<template>
    <div>
        <iframe src="https://ds.iocity.iotech.pt" frameborder="0"></iframe>
    </div>
</template>

<script>
    import themeConfig from '@themeConfig';
    export default {
        name: 'Reports',
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                // access to component instance via `vm`
                vm.$store.commit('appConfig/UPDATE_APP_BAR_TYPE', 'button');
                vm.$store.commit('appConfig/UPDATE_FOOTER_TYPE', 'hidden');
                vm.$store.commit('appConfig/UPDATE_APP_CONTENT_WIDTH', 'full');
            });
        },
        beforeRouteLeave(to, from, next) {
            this.$store.commit('appConfig/UPDATE_APP_BAR_TYPE', themeConfig.appBar.type);
            this.$store.commit('appConfig/UPDATE_FOOTER_TYPE', themeConfig.footer.type);
            this.$store.commit('appConfig/UPDATE_APP_CONTENT_WIDTH', themeConfig.app.contentWidth);
            next();
        },
    };
</script>

<style lang="scss" scoped>
    iframe,
    div {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
</style>
